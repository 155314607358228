@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/548f33803d3d2f1d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/b68f1faef7b9f6cd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/5deff0735db94219-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/d18df599cb295e4c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/1192e3868773da55-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/3876361aff53ae40-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/27cba7c254e72b21-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__Campton_22f405';
src: url(/_next/static/media/073cc96d9d0ad7fa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: italic;
}@font-face {font-family: '__Campton_Fallback_22f405';src: local("Arial");ascent-override: 87.71%;descent-override: 23.84%;line-gap-override: 0.00%;size-adjust: 109.91%
}.__className_22f405 {font-family: '__Campton_22f405', '__Campton_Fallback_22f405'
}.__variable_22f405 {--campton: '__Campton_22f405', '__Campton_Fallback_22f405'
}

